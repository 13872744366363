<script>
import SessionContainer from '@/modules/games-shared/SessionContainer';
import config from './config';

export default {
    name: 'GameFaces',
    extends: SessionContainer,
    config
};
</script>
